import { FC } from 'react';

import { ConfirmationModal } from 'modals';

interface RelationshipsConcludeDialogProps {
  dialogTitle: string;
  dialogDescription: string;
  cancelButtonLabel: string;
  concludeRelationshipButtonLabel: string;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onConcludeRelationship: () => void;
}

const RelationshipsConcludeDialog: FC<RelationshipsConcludeDialogProps> = ({
  dialogTitle,
  dialogDescription,
  cancelButtonLabel,
  concludeRelationshipButtonLabel,
  open,
  loading,
  onClose,
  onConcludeRelationship,
}) => {
  return (
    <ConfirmationModal
      open={open}
      title={dialogTitle}
      description={dialogDescription}
      isLoading={loading}
      actions={[
        {
          label: cancelButtonLabel,
          action: onClose,
          color: 'inherit',
          variant: 'outlined',
          textColor: 'text.secondary',
          isLoadingButton: true,
        },
        {
          label: concludeRelationshipButtonLabel,
          action: onConcludeRelationship,
          color: 'info',
          variant: 'contained',
          isLoadingButton: true,
          dataCyLabel:
            'components_relationship_relationshipConclude_relationshipsConcludeDialog_button',
        },
      ]}
      handleClose={onClose}
    />
  );
};

export default RelationshipsConcludeDialog;
