// external
import { Divider, Drawer, Typography, useTheme } from '@mui/material';
import React from 'react';

// components
import {
  RelationshipGoals,
  RelationshipNotes,
  RelationshipSessions,
  RelationshipViewProfile,
} from 'components';

// types
import { useOrganizationPrograms } from '@guider-global/sanity-hooks';
import { IRelationship, Program } from '@guider-global/shared-types';

import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization, useMobileMediaQuery } from 'hooks';

interface IRelationshipDetailsSidebarProps {
  relationship: IRelationship;
  isGroupProgram: boolean;
  isGuide: boolean;
}

export const RelationshipDetailsSidebar: React.FC<
  IRelationshipDetailsSidebarProps
> = ({ relationship, isGroupProgram, isGuide }) => {
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const programSlug = relationship?.programSlug ?? undefined;

  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  let program: Program | undefined;

  if (programSlug) {
    program = getProgram(programSlug);
  }

  const programName = program?.metadata?.program_name;
  return (
    <Drawer
      data-cy="components_relationship_drawer"
      open={true}
      anchor="right"
      variant="permanent"
      sx={{
        height: '100%',
        '& .MuiDrawer-paper': {
          height: '100%',
          overflowY: 'auto',
          width: isMobile ? '100vw' : '320px',
          borderLeft: `1px solid ${theme.palette.divider}`,
          position: isMobile ? 'absolute' : 'static',
          p: 3,
        },
      }}
    >
      {isMobile && programName && (
        <>
          <Typography variant="body2" sx={{ py: 2 }}>
            {programName}
          </Typography>
          <Divider sx={{ mb: 5 }} />
        </>
      )}
      <RelationshipViewProfile relationship={relationship} />
      <RelationshipGoals relationship={relationship} isGuide={isGuide} />
      <RelationshipSessions
        relationship={relationship}
        isGroupProgram={isGroupProgram}
        isGuide={isGuide}
      />
      <RelationshipNotes relationship={relationship} />
    </Drawer>
  );
};

export default RelationshipDetailsSidebar;
